import { h } from 'preact';
import { useEffect, useState, useRef } from 'preact/hooks';
import { InformationIcon } from '../Icons/InformationIcon';
import { cx } from 'utils/cx';

import constants from '../../constants';
import styles from './InformationBanner.scss';
import { Link } from 'react-router-dom';

const InformationBanner = () => {
  const [isVertical, setVertical] = useState(false);
  const bannerRef = useRef(null);

  const {
    userGuideLink,
    hugeModePopUp: { btnGoBack },
    displayMode,
  } = constants;
  const { displayValue, information } = displayMode.published;

  // Effect to determine if the banner is vertical based on dimensions
  useEffect(() => {
    if (bannerRef.current) {
      setVertical(
        bannerRef.current.clientHeight > 68 &&
          bannerRef.current.clientWidth < 700
      );
    }
  });

  // Function to get the banner's CSS classes
  const getBannerClasses = () => {
    return cx(styles, [
      'information-banner',
      isVertical ? 'information-banner--vertical' : '',
    ]);
  };

  return (
    <div ref={bannerRef} className={getBannerClasses()} tabIndex="0">
      <InformationIcon />
      <b className={styles[['information-banner__display-mode']]}>
        {displayValue}
      </b>
      <span className={styles[['information-banner__content']]}>
        {information}
      </span>
      <Link
        to="/"
        href={userGuideLink}
        reloadDocument
        className={styles['information-banner__go-back']}
        ariaLabel={'Go back to EFP'}
      >
        {btnGoBack}
      </Link>
    </div>
  );
};

export { InformationBanner };
