/**
 *  EFP needs to generate the <ins> tag for the offer loading.
 *  We need to specify filter described below to get an offer.
 *
 *  The ins tag works for ads that use iframe/JavaScript or JavaScript tags and improves Verification and Reporting data. It provides benefits for adverstisers and publishers.
 *  Ins tag: https://support.google.com/campaignmanager/answer/6080468?hl=en
 *
 * @param {object} options
 * @param {string} options.adCount
 * @param {string} options.dataWidth
 * @param {string} options.dataHeight
 * @param {string} options.dataPlacementId
 * @param {string} options.zipCode
 * @param {string} options.modelYearValue
 * @param {string} options.family
 * @param {string} options.dataDivisionCode
 * @returns {string} ins HTML string with ad parameters in the tag
 *
 * @example
 * generateAdTemplate({
 *  adCount: '0',
 *  dataDivisionCode: 'Y'
 *  dataHeight: '250',
 *  dataPlacementId: 'XYZ',
 *  dataWidth: '300',
 *  family: 'UY2021XYZ',
 *  modelYearValue: '2021',
 *  zipCode: '123',
 * })
 */

const generateAdTemplate = (adSpecs) => {
  const {
    adCount,
    dataDivisionCode,
    dataHeight,
    dataPlacementId,
    dataWidth,
    family,
    modelYearValue,
    zipCode = '',
  } = adSpecs || {};

  // Validate required fields
  if (
    typeof adCount !== 'number' ||
    !dataDivisionCode ||
    !dataHeight ||
    !dataPlacementId ||
    !dataWidth ||
    !family ||
    !modelYearValue
  ) {
    throw new Error('Missing required fields');
  }

  return `<ins data-testid='ad-loaded' class='dcmads ad-${adCount}' style='display:inline-block;width:${dataWidth}px;height:${dataHeight}px'
    data-dcm-placement='N1047766.3526078GDN-GOOGLEDISPLA/B24630784.${dataPlacementId}'
    data-dcm-rendering-mode='script'
    data-dcm-https-only
    data-dcm-resettable-device-id=''
    data-dcm-app-id=''
    data-dcm-param-zip='${zipCode}'
    data-dcm-param-modelyear='${modelYearValue}'
    data-dcm-param-family='${family}'
    data-dcm-param-language='EN'
    data-dcm-param-creative_type='non-endemic'
    data-dcm-param-brand_division_code='${dataDivisionCode}'
    data-dcm-param-rpt_dimension_year="${modelYearValue}">
  </ins>`;
};

export { generateAdTemplate };
