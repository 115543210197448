import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getOfferData } from './getOfferData/getOfferData';

const firebaseConfig = {
  apiKey: process.env.FB_API_KEY,
  authDomain: process.env.FB_AUTH_DOMAIN,
  databaseURL: process.env.FB_DB_URL,
  projectId: process.env.FB_PROJECT_ID,
  storageBucket: process.env.FB_STORAGE_BUCKET,
  messagingSenderId: process.env.FB_MSG_SENDER_ID,
  appId: process.env.FB_APP_ID,
  measurementId: process.env.FB_MEASUREMENT_ID,
};
console.log('process.env.FB_API_KEY', process.env.FB_API_KEY);
const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);

export { getOfferData };
export default firestore;
